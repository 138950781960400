<template>
  <div class="portfolio-content">
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-bookxm.webp" :disableBack="true" title="BookXM" 
        :images="[
          'portfolio-multimedia/main-bookxm.webp',
          'portfolio-multimedia/collage-bookxm.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
        <section class="portfolio-part2-section1">
          <article>
            <h3><b>BookXM (MarketXM)</b></h3>
            <p>Creation of a platform for the management of financial credits of different types in a parametric way.</p>
            <ul class="description-lu">
                <li>Creation of functionalities in <b>Node.js</b> with <b>Javascript</b> and <b>Typescript</b>.</li>
                <li>Management of <b>Prisma ORM</b> with <b>PostgreSQL</b>.</li>
                <li>Development of <b>SPA</b> in <b>React.js</b>.</li>
                <li>Implementation of <b>API-REST</b> and <b>Microservices</b> system using <b>Clean Architecture</b> and <b>Event-Driven Architecture</b> with <b>Kafka</b>.</li>
                <li>Implementation of authentication and authorization mechanisms with <b>JWT</b>.</li>
                <li>Configuration of <b>AWS</b> services such as: <b>IAM, S3, API Gateway, Lambda and Cloud Watch</b>. </li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-collxm.webp" :disableBack="true" title="CollXM"
        :images="[
          'portfolio-multimedia/main-collxm.webp',
          'portfolio-multimedia/collage-collxm.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
        <section class="portfolio-part2-section2">
          <article>
            <h3><b>CollXM (MarketXM)</b></h3>
            <p>Creation of a platform that allows the exchange of derivative financial services between different types of entities.</p>
            <ul class="description-lu">
                <li>Creation of functionalities in <b>Node.js</b> with <b>Javascript</b>.</li>
                <li>Management of <b>Sequelize ORM</b> and <b>Prisma ORM</b> with <b>PostgreSQL</b>.</li>
                <li>Development of <b>SPA</b> in <b>React.js</b>.</li>
                <li>Implementation of <b>API-REST</b> using <b>Clean Architecture</b>.</li>
                <li>Implementation of authentication and authorization mechanisms with <b>JWT</b>.</li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-updaty.webp" :disableBack="true" title="Updaty" 
        :images="[
          'portfolio-multimedia/main-updaty.webp',
          'portfolio-multimedia/collage-updaty.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
        <section class="portfolio-part2-section1">
          <article>
            <h3><b>Updaty</b></h3>
            <p>Creation of NPM package to get and format internet real data with AI and keep it up to date.</p>
            <ul class="description-lu">
                <li>Creation of <b>NPM</b> package with <b>Node.js</b> and <b>Javascript</b>.</li>
                <li>Implementation of <b>ChatGPT AI</b> on Node.js to interpterate and format internet data results.</li>
                <li>Use of <b>SQLite</b> to manage internet real data information.</li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-rest-api.webp" :disableBack="true" title="Clean Architecture REST-API general template"
        :images="[
          'portfolio-multimedia/main-rest-api.webp',
          'portfolio-multimedia/collage-rest-api.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
        <section class="portfolio-part2-section2">
          <article>
            <h3><b>Clean Architecture REST-API general template</b></h3>
            <p>Creation of a template that allows standardizing the use of clean architecture with dynamic management of routes, generalization of authentication and authorization systems, dynamic creation of documentation and general error, validation and response handlers.</p>
            <ul class="description-lu">
                <li>Development of <b>Node.js</b> Clean architecture REST-API structure.</li>
                <li>Applying linters, aliases, general response, errors general handler, middlewares, <b>swagger</b>, schemas, <b>OOP</b>, enviroment variables,  authentication and authorization with <b>JWT</b>, Encrypt passwords, nodemon and winston <b>Logger</b>.</li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-cerciberpage.webp" :disableBack="true" title="Cerciber Webpage" 
        :images="[
          'portfolio-multimedia/main-cerciberpage.webp',
          'portfolio-multimedia/collage-cerciberpage.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
        <section class="portfolio-part2-section1">
          <article>
            <h3><b>Cerciber Webpage</b></h3>
            <p>Creation Single Page Application to show information about me.</p>
            <ul class="description-lu">
                <li>Design and development of a <b>SPA</b> on <b>Vue.js</b>.</li>
                <li>Deployment with <b>Namecheap</b> and <b>GitHub Pages</b>.</li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-k-boom.webp" :disableBack="true" title="K-Boom"
        :images="[
          'portfolio-multimedia/main-k-boom.webp',
          'portfolio-multimedia/collage-video-k-boom.mp4',
          'portfolio-multimedia/collage-k-boom.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
        <section class="portfolio-part2-section2">
          <article>
            <h3><b>K-Boom (Nullspace)</b></h3>
            <p>Creation of Android App to show interactive murals through the cell phone camera. </p>
            <ul class="description-lu">
                <li>Creation of <b>Android</b> App With <b>Java</b> and <b>Kotlin</b>. </li>
                <li>Use of <b>Vuforia</b> and <b>C++</b> to allow the use of augmented reality.</li>
                <li><b>XML</b> management for <b>UI</b> and <b>UX</b> design.</li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-nullspacepage.webp" :disableBack="true" title="Nullspace Webpage" 
        :images="[
          'portfolio-multimedia/main-nullspacepage.webp',
          'portfolio-multimedia/collage-video-homepage-nullspace.mp4',
          'portfolio-multimedia/collage-nullspacepage.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
        <section class="portfolio-part2-section1">
          <article>
            <h3><b>Nullspace Webpage (Nullspace)</b></h3>
            <p>Creation of a single web application to show information about Nullspace company.</p>
            <ul class="description-lu">
                <li>Creation of a <b>SPA</b> on <b>Vue.js</b>.</li>
                <li>Creation of part of the 3D environment, colors interactions and particles using <b>Three.js</b>.</li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-cycling.webp" :disableBack="true" title="Time Detector System"
        :images="[
          'portfolio-multimedia/main-cycling.webp',
          'portfolio-multimedia/collage-cycling.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
        <section class="portfolio-part2-section2">
          <article>
            <h3><b>Time Detector System (Cycling & Race)</b></h3>
            <p>Development of a system for detecting bicycles times and generating results tables in cycling races. </p>
            <ul class="description-lu">
                <li>Development of a <b>monolithic</b> system using <b>Java SE</b> and <b>Java FX</b>.</li>
                <li>Administration of local information using <b>SQLite</b>.</li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-sigmath.webp" :disableBack="true" title="Sigmath" 
        :images="[
          'portfolio-multimedia/main-sigmath.webp',
          'portfolio-multimedia/collage-sigmath.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
        <section class="portfolio-part2-section1">
          <article>
            <h3><b>Sigmath</b></h3>
            <p>Development of a platform to create graphical and programmatic mathematics in an easy and intuitive way.</p>
            <ul class="description-lu">
                <li>Development of a <b>REST-API</b> with <b>Node.js</b> and <b>Express.js</b> and a <b>SPA</b> with <b>Vue.js</b> compiling <b>Python</b> and <b>LaTeX</b> using <b>5-layered architecture</b>.</li>
                <li>Database administration with <b>PostgreSQL</b> and <b>Sequelize</b>.</li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-trimcode.webp" :disableBack="true" title="TrimCode"
        :images="[
          'portfolio-multimedia/main-trimcode.webp',
          'portfolio-multimedia/collage-trimcode.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
      <section class="portfolio-part2-section2">
          <article>
            <h3><b>TrimCode</b></h3>
            <p>Development of a desktop application to save and execute scripts from different programming languages.</p>
            <ul class="description-lu">
                <li>Development of a desktop application on <b>Java SE</b> to compile <b>C, C++, Python, Java, MathLab</b> and <b>Javascript</b>.</li>
                <li>Use of <b>batch commands</b> to configure different type compilers.</li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-structfile.webp" :disableBack="true" title="StructFile" 
        :images="[
          'portfolio-multimedia/main-structfile.webp',
          'portfolio-multimedia/collage-structfile.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
        <section class="portfolio-part2-section1">
          <article>
            <h3><b>StructFile</b></h3>
            <p>Creation of a programming language based on filters and patterns to manage plain text, files and directories in the operating system.</p>
            <ul class="description-lu">
                <li>Design and development of a <b>programming language</b>.</li>
                <li>Use of <b>Java SE, Python, ANTLR</b> and <b>RegEx</b> to implement lexical, syntactic and semantic logic.</li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-findcode.webp" :disableBack="true" title="FindCode"
        :images="[
          'portfolio-multimedia/main-findcode.webp',
          'portfolio-multimedia/collage-findcode.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
        <section class="portfolio-part2-section2">
          <article>
            <h3><b>FindCode</b></h3>
            <p>Development of a desktop application to save, manage and comment pieces of code interactively.</p>
            <ul class="description-lu">
                <li>Development of a desktop application on <b>Java SE</b>.</li>
                <li>Connection with <b>MySQL</b> to manage all data persistence.</li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-tankattack.webp" :disableBack="true" title="TankAttack" 
        :images="[
          'portfolio-multimedia/main-tankattack.webp',
          'portfolio-multimedia/collage-tankattack.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
        <section class="portfolio-part2-section1">
          <article>
            <h3><b>TankAttack</b></h3>
            <p>Development of a local 2-player desktop game to play capture the flag with tanks.</p>
            <ul class="description-lu">
                <li>Development of a desktop game with <b>Java SE</b> and <b>Java Swing</b>.</li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-part1">
        <PhotoShapeRelative pathImage = "portfolio-multimedia/main-quicklist.webp" :disableBack="true" title="QuickList"
        :images="[
          'portfolio-multimedia/main-quicklist.webp',
          'portfolio-multimedia/collage-quicklist.webp'
        ]"
      ></PhotoShapeRelative>
      </div>
      <div class="portfolio-part2">
        <section class="portfolio-part2-section2">
          <article>
            <h3><b>QuickList</b></h3>
            <p>Creation of an information system for the integration of administrative-academic responsibilities on SENA.</p>
            <ul class="description-lu">
                <li>Creation of an information system on <b>Java SE</b>.</li>
                <li>Connection with <b>SQLServer</b> to manage all data persistence.</li>
            </ul>
          </article>
        </section>
      </div>
      <div class="portfolio-shape-back-curve">
      </div>
  </div>
</template>

<script>
import PhotoShapeRelative from '../components/Main/PhotoShapeRelative.vue'

export default {
  name: 'portfolioView',
  components: {
    PhotoShapeRelative
  }
}
</script>

<style scoped>
.portfolio-content {
  height: calc(100% - 160px);
  padding-top: 80px;
  padding-bottom: 80px;
}

.portfolio-part1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-part2-section1 {
  z-index: 100;
  padding: 20px;
  margin: 20px;
  line-height : 35px;
  background-color: white;
  border: 3px solid var(--degrade3);    
  transition: var(--color-transition-time);
  border-radius: 10px; 
  overflow: scroll;
}

.portfolio-part2-section2 {
  z-index: 100;
  padding: 20px;
  margin: 20px;
  line-height : 35px;
  background-color: white;
  border: 3px solid var(--degrade3);    
  transition: var(--color-transition-time);
  border-radius: 10px; 
  overflow: scroll;
}

.portfolio-shape-back-curve {
  position: absolute;
  min-width: 300%;
  max-width: 300%;
  mask-size: 40%;
  height: 200%;
  margin-top: 80%;
  margin-left: 80%;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  
  background: var(--degrade2);
  transition: var(--color-transition-time);

  mask-image: url('~@/user_interface/assets/Curve.webp');
  z-index: -1;

  shape-outside: url('~@/user_interface/assets/Curve.webp');
  shape-image-threshold: 0.5;
  shape-margin: 20px;
  float: left;
}

.portfolio-content {
  width: 100%;
  overflow: scroll;
}

.description-lu {
    list-style-type: none;
}

.description-lu > li {
    margin-bottom: 10px; /* Espaciado entre elementos li */
    position: relative;
}

.description-lu > li::before {
    content: "👉🏻"; /* Reemplaza el emoji por el que desees */
    display: inline-block;
    margin-right: 8px; /* Espaciado entre el emoji y el texto */
    position: absolute;
    left: -2em; /* Alinea el emoji antes del comienzo del texto */
}
</style>
