<template>
  <aside>

  </aside>
</template>

<script>
export default {
  name: 'asideComponent'
}
</script>

<style scoped>
</style>
